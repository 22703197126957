import { defaultsDeep } from 'lodash-es'
import type { RecursiveRequired, Settings } from '#/global'
import settingsDefault from '@/settings.default'

const globalSettings: Settings.all = {
  app: {
    lightTheme: 'classic',
  },
  home: {
    enable: false,
  },
  menu: {
    subMenuCollapse: true,
  },
  tabbar: {
    enable: true,
  },
}
export default defaultsDeep(globalSettings, settingsDefault) as RecursiveRequired<Settings.all>
