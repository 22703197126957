import type { RouteRecordRaw } from 'vue-router'

const Distribution: RouteRecordRaw[] = [
  {
    path: '/task',
    component: () => import('@/layouts/index.vue'),
    meta: {
      title: '任务管理',
      icon: 'ant-design:schedule-outlined',
      breadcrumb: true,
      cache: true,
    },
    redirect: { name: 'DistributorTaskList' },
    children: [
      {
        path: 'list',
        name: 'DistributorTaskList',
        component: () => import('@/views/distribution/task/task/list.vue'),
        meta: {
          title: '任务列表',
          icon: 'ant-design:unordered-list-outlined',
          breadcrumb: true,
          cache: true,
        },
      },
      {
        path: 'visit/list/:id',
        name: 'DistributorTaskVisitList',
        component: () => import('@/views/distribution/task/task/visit/list.vue'),
        meta: {
          title: '回访任务',
          breadcrumb: true,
          sidebar: false,
          cache: true,
        },
      },
      {
        path: 'group/list',
        name: 'DistributorTaskGroupList',
        component: () => import('@/views/distribution/task/task-group/list.vue'),
        meta: {
          title: '任务组列表',
          icon: 'ant-design:unordered-list-outlined',
          breadcrumb: true,
          cache: true,
        },
      },
      {
        path: 'group/detail/:id',
        name: 'DistributorTaskGroupDetail',
        component: () => import('@/views/distribution/task/task-group/detail.vue'),
        meta: {
          title: '任务组详情',
          breadcrumb: true,
          sidebar: false,
          cache: true,
        },
      },
    ],
  },
]

export default Distribution
