const fakeModuleList = window.__VITE__PLUGIN__FAKE__SERVER__.fakeModuleList;
					const { pathToRegexp, match } = window.__VITE__PLUGIN__FAKE__SERVER__.pathToRegexp;
					window.__VITE__PLUGIN__FAKE__SERVER__.xhook.before(async function(req, callback) {
						function sleep(time) {
	return new Promise((resolve) => {
		const timer = setTimeout(() => {
			resolve(timer);
			clearTimeout(timer);
		}, time);
	});
}
						function tryToJSON(str) {
	let result = "";
	try {
		result = JSON.parse(str);
	} catch (err) {
		result = str;
	}
	return result;
}
						async function getResponse({
	URL,
	req,
	fakeModuleList,
	pathToRegexp,
	match,
	basename,
	defaultTimeout,
	globalResponseHeaders,
}) {
	/**
	 * Join two paths into a complete path
	 */
	function joinPathname(a, b) {
		const aPathname = new URL(a, "http://localhost:5173/").pathname;
		const bPathname = new URL(b, "http://localhost:5173/").pathname;
		return aPathname.endsWith("/") ? aPathname.slice(0, -1) + bPathname : aPathname + bPathname;
	}

	if (req.url) {
		const instanceURL = new URL(req.url, "http://localhost:5173/");

		// https://nodejs.org/api/url.html#urlpathname
		// Invalid URL characters included in the value assigned to the pathname property are percent-encoded
		const pathname = instanceURL.pathname;

		const matchRequest = fakeModuleList.find((item) => {
			if (!pathname || !item || !item.url) {
				return false;
			}
			const method = item.method ?? "GET";
			const reqMethod = req.method ?? "GET";
			if (method.toUpperCase() !== reqMethod.toUpperCase()) {
				return false;
			}
			const realURL = joinPathname(basename, item.url);
			return pathToRegexp(realURL).test(pathname);
		});
		if (matchRequest) {
			const {
				response,
				rawResponse,
				timeout = defaultTimeout,
				statusCode,
				statusText,
				url,
				headers: responseHeaders = {},
			} = matchRequest;

			if (timeout) {
				await sleep(timeout);
			}

			const joinedUrl = joinPathname(basename, url);
			const urlMatch = match(joinedUrl, { encode: encodeURI });

			const searchParams = instanceURL.searchParams;
			const query = {};
			for (const [key, value] of searchParams.entries()) {
				if (query.hasOwnProperty(key)) {
					const queryValue = query[key];
					if (Array.isArray(queryValue)) {
						queryValue.push(value);
					} else {
						query[key] = [queryValue, value];
					}
				} else {
					query[key] = value;
				}
			}

			let params = {};

			if (pathname) {
				const matchParams = urlMatch(pathname);
				if (matchParams) {
					params = matchParams.params;
				}
			}

			return {
				response,
				rawResponse,
				timeout,
				statusCode: statusCode ?? 200,
				statusText,
				url: req.url,
				query,
				params,
				responseHeaders: new Headers({ ...globalResponseHeaders, ...responseHeaders }),
			};
		}
	}
}

						function headersToObject(headers) {
							const headersObject = {};
							for (const [name, value] of headers.entries()) {
								headersObject[name] = value;
							}
							return headersObject;
						}

						const responseResult = await getResponse({
							URL,
							req,
							fakeModuleList,
							pathToRegexp,
							match,
							basename: "",
							defaultTimeout: undefined,
							globalResponseHeaders: {}
						});
						if (responseResult) {
							const { response, statusCode, statusText, url, query, params, responseHeaders } = responseResult ?? {};
							if (response && typeof response === "function") {
								const fakeResponse = await Promise.resolve(
									response({ url, body: tryToJSON(req.body), rawBody: req.body, query, params, headers: req.headers })
								);
								if(req.isFetch) {
									if (typeof fakeResponse === "string") {
										if (!responseHeaders.get("Content-Type")) {
											responseHeaders.set("Content-Type", "text/plain");
										}
										callback(new Response(
											fakeResponse,
											{
												statusText,
												status: statusCode,
												headers: headersToObject(responseHeaders),
											}
										));
									} else {
										if (!responseHeaders.get("Content-Type")) {
											responseHeaders.set("Content-Type", "application/json");
										}
										callback(new Response(
											JSON.stringify(fakeResponse, null, 2),
											{
												statusText,
												status: statusCode,
												headers: headersToObject(responseHeaders),
											}
										));
									}
								} else {
									if(!req.type || req.type.toLowerCase() === "text") {
										if (!responseHeaders.get("Content-Type")) {
											responseHeaders.set("Content-Type", "text/plain");
										}
										callback({
											statusText,
											status: statusCode,
											text: fakeResponse,
											data: fakeResponse,
											headers: headersToObject(responseHeaders),
										});
									} else if (req.type.toLowerCase() === "json") {
										if (!responseHeaders.get("Content-Type")) {
											responseHeaders.set("Content-Type", "application/json");
										}
										callback({
											statusText,
											status: statusCode,
											data: fakeResponse,
											headers: headersToObject(responseHeaders),
										});
									} else if (req.type.toLowerCase() === "document") {
										if (!responseHeaders.get("Content-Type")) {
											responseHeaders.set("Content-Type", "application/xml");
										}
										const parser = new DOMParser();
										const xmlDoc = parser.parseFromString(fakeResponse,"application/xml");
										callback({
											statusText,
											status: statusCode,
											xml: xmlDoc,
											data: xmlDoc,
											headers: headersToObject(responseHeaders),
										});
									} else {
										// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
										// "arraybuffer" | "blob"
										callback({
											statusText,
											status: statusCode,
											data: fakeResponse,
											headers: headersToObject(responseHeaders),
										});
									}
								}
							}
							if (false){
								const requestMethod = req.method ? req.method.toUpperCase() : "GET";
								console.log("%c request invoke" + " %c" + requestMethod + " " + req.url, "color: green", "color: blue");
							}
						} else {
							// next external URL
							callback();
						}
					});