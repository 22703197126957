<route lang="yaml">
meta:
  title: 主页
  icon: ant-design:home-twotone
</route>

<script setup lang="ts">
</script>

<template>
  <div />
</template>

<style lang="scss" scoped>

</style>
