import axios from 'axios'
import api from '../index'

export default {
  // 获取 options
  options: (data: {
    type: string
    search?: string
    limit?: number
    ids?: number[]
    cursor?: string
  }) => api.get(`api/crm/common/v1/options/${data.type}`, {
    params: Object.assign({
      limit: 10,
    }, data),
  }),

  treeOptions: (data: {
    type: string
    search?: string
    limit?: number
    ids?: number[]
    cursor?: string
  }) => api.get(`api/crm/common/v1/tree-options/${data.type}`),

  // 获取分销员ID(临时方案, 因为目前logto缺少该数据)
  getDistributorIDByMobile: (mobile: string) => api.get('api/crm/common/v1/distributor-id-by-mobile', {
    params: {
      mobile,
    },
  }),

  // 获取 metabase 嵌入链接
  getMetabaseEmbedUrl: (data: {
    resource: any
    params: any
    expire: number
  }) => api.post('api/crm/common/v1/metabase-embed-url', data),

  getAliyunOssUploadUrl: (data: {
    contentType: string
    category: string
  }) => api.post('api/crm/common/v1/aliyun-oss-upload-url', data),

  async preUpload(category: string, file: File): Promise<{ key: string }> {
    try {
      // 获取阿里云 OSS 上传 URL
      const res = await this.getAliyunOssUploadUrl({
        contentType: file.type,
        category,
      }) as any

      if (!res || !res.uploadURL || !res.key) {
        throw new Error('获取阿里云 OSS 上传 URL 失败')
      }

      const { uploadURL, key } = res

      // 上传文件到 OSS
      const fileReader = new FileReader()
      const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        fileReader.onload = () => resolve(fileReader.result as ArrayBuffer)
        fileReader.onerror = reject
        fileReader.readAsArrayBuffer(file)
      })

      const response = await axios.put(uploadURL, arrayBuffer, {
        headers: {
          'Content-Type': file.type,
        },
      })

      if (response.status !== 200) {
        throw new Error(`上传文件到阿里云 OSS 失败, 状态码: ${response.status}`)
      }

      return { key }
    }
    catch (error) {
      console.error('preUpload 方法出错:', error)
      throw error
    }
  },

  async downloadFile(url: string, filename: string) {
    try {
      const res = await axios.get(url, {
        responseType: 'blob',
      })

      const blob = new Blob([res.data], {
        type: res.headers['content-type'],
      })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
    }
    catch (error) {
      console.error('downloadFile 方法出错:', error)
      throw error
    }
  },
}
