import type { RouteRecordRaw } from 'vue-router'

const TagCrm: RouteRecordRaw = {
  path: '/tag',
  component: () => import('@/layouts/index.vue'),
  meta: {
    title: '标签管理',
    icon: 'ant-design:tag-outlined',
    breadcrumb: true,
  },
  children: [
    {
      path: 'list',
      name: 'TagList',
      component: () => import('@/views/crm/tag/list.vue'),
      meta: {
        title: '标签列表',
        icon: 'ant-design:unordered-list-outline',
        breadcrumb: true,
        cache: true,
      },
    },
    {
      path: 'group',
      name: 'TagGroupList',
      component: () => import('@/views/crm/tag/group/group.vue'),
      meta: {
        title: '标签组',
        icon: 'ant-design:tags-outlined',
        breadcrumb: true,
        cache: true,
      },
    },
    {
      path: 'log',
      name: 'TagLogList',
      component: () => import('@/views/crm/tag/log/log.vue'),
      meta: {
        title: '标签日志',
        icon: 'ant-design:file-text-outlined',
        breadcrumb: true,
        cache: true,
      },
    },
  ],
}

export default TagCrm
