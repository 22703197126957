import '@/utils/baidu'

import '@/utils/system.copyright'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import Message from 'vue-m-message'
import 'vue-m-message/dist/style.css'

import 'overlayscrollbars/overlayscrollbars.css'

import type {LogtoConfig} from '@logto/vue'
import {createLogto} from '@logto/vue'
import App from './App.vue'
import pinia from './store'
import router from './router'
import ui from './ui-provider'
import {setupI18n} from './locales'

// 自定义指令
import directive from '@/utils/directive'

// 错误日志上报
import errorLog from '@/utils/error.log'

// 加载 svg 图标
import 'virtual:svg-icons-register'

// 加载 iconify 图标
import {downloadAndInstall} from '@/iconify'
import icons from '@/iconify/index.json'

import 'virtual:uno.css'

// 全局样式
import '@/assets/styles/globals.scss'

const app = createApp(App)
app.use(FloatingVue, {
  distance: 12,
})
app.use(Message)
app.use(pinia)
app.use(router)
app.use(ui)
app.use(setupI18n())
directive(app)
errorLog(app)
if (icons.isOfflineUse) {
  for (const info of icons.collections) {
    downloadAndInstall(info)
  }
}

const endpoint = import.meta.env.VITE_APP_LOGTO_ENDPOINT
const appId = import.meta.env.VITE_APP_LOGTO_APP_ID
const resource = import.meta.env.VITE_APP_LOGTO_RESOURCE

const config: LogtoConfig = {
  endpoint: endpoint,
  appId: appId,
  resources: [resource],
  scopes: ['profile','phone', 'email', 'custom_data', 'identity', 'role']
}

app.use(createLogto, config)

app.mount('#app')
