import type { RouteRecordRaw } from 'vue-router'

const CustomerCrm: RouteRecordRaw = {
  path: '/customer',
  component: () => import('@/layouts/index.vue'),
  meta: {
    title: '客户管理',
    icon: 'ant-design:team-outlined',
    breadcrumb: true,
  },
  children: [
    {
      path: 'list',
      name: 'CustomerList',
      component: () => import('@/views/crm/customer/list.vue'),
      meta: {
        title: '客户列表',
        icon: 'ant-design:unordered-list-outlined',
        breadcrumb: true,
        cache: true,
      },
    },
    {
      meta: {
        title: '客户详情',
        sidebar: false,
      },
      path: 'detail/:id',
      name: 'CustomerDetail',
      component: () => import('@/views/crm/analysis/customer/detail.vue'),
    },
  ],
}

export default CustomerCrm
