import type { RouteRecordRaw } from 'vue-router'

const InsightsCrm: RouteRecordRaw = {
  path: '/insights',
  component: () => import('@/layouts/index.vue'),
  meta: {
    title: '画像洞察',
    icon: 'ant-design:solution-outlined',
    breadcrumb: true,
  },
  children: [
    {
      path: 'rfm',
      name: 'RFMIndex',
      component: () => import('@/views/crm/insights/rfm/index.vue'),
      meta: {
        title: 'RFM分析',
        icon: 'tdesign:chart-bubble',
        breadcrumb: true,
        cache: true,
      },
    },
  ],
}

export default InsightsCrm
