<script setup lang="ts">
import { theme } from 'ant-design-vue'
import { locales } from './index'
import useSettingsStore from '@/store/modules/settings'

const settingsStore = useSettingsStore()
</script>

<template>
  <AConfigProvider :locale="locales[settingsStore.lang]" :theme="settingsStore.settings.app.colorScheme === 'dark' ? { algorithm: [theme.darkAlgorithm] } : {}">
    <slot />
  </AConfigProvider>
</template>
